<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="卡号" prop="simNo">
        <a-input v-model="form.simNo" :disabled="readOnly" placeholder="请输入卡号" />
      </a-form-model-item>
      <a-form-model-item label="sim卡ICCID号码" prop="iccid">
        <a-input v-model="form.iccid" :disabled="readOnly" placeholder="请输入sim卡ICCID号码" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="loading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDeviceSim, addDeviceSim, updateDeviceSim } from '@/api/jt808/deviceSim'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      readOnly: false,
      reMountedForm: true,
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        simNo: null,
        iccid: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        simNo: [{ required: true, message: '卡号不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.loading = false
      this.form = {
        id: null,
        simNo: null,
        iccid: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.readOnly = false
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      this.reset()
      this.formType = 2
      if (type === 'read') {
        this.readOnly = true
      } else {
        this.readOnly = false
      }
      const id = row ? row.id : ids
      getDeviceSim(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDeviceSim(this.form)
              .then(response => {
                this.loading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            addDeviceSim(this.form)
              .then(response => {
                this.loading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
