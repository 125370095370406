/*
 * @Auth: linjituan
 * @Date: 2021-11-11 10:32:55
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-22 11:37:04
 */
import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询sim卡管理列表
export function listDeviceSim(query) {
  return request({
    url: '/sim/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询sim卡管理详细
export function getDeviceSim(id) {
  return request({
    url: '/sim/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增sim卡管理
export function addDeviceSim(data) {
  return request({
    url: '/sim',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改sim卡管理
export function updateDeviceSim(data) {
  return request({
    url: '/sim',
    method: 'put',
    baseURL,
    data: data
  })
}

// 删除sim卡管理
export function delDeviceSim(id) {
  return request({
    url: '/sim/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出sim卡管理
export function exportDeviceSim(query) {
  return request({
    url: '/sim/export',
    method: 'get',
    baseURL,
    params: query
  })
}

// 模版下载接口
export function importTemplate() {
  return request({
    url: '/sim/importTemplate',
    baseURL,
    responseType: 'blob',
    method: 'get'
  })
}

// 导入sim卡管理
export function importData(data) {
  return request({
    url: '/sim/importData',
    baseURL,
    method: 'post',
    data: data
  })
}
